import type { ComponentType } from "react"

export function noFollow(Component): ComponentType {
    return (props) => {
        const newProps = {
            ...props,
            rel: props.rel === "noopener" ? "nofollow" : props.rel,
        }
        return <Component {...newProps} />
    }
}
